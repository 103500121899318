// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import addFile from "../../assets/images/icon/add-btn.svg";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Axois
import axios from "axios";

// Alert
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const BannerSideBAr = ({ type }) => {
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    bannerData,
    setBannerData,
    bannerSideBarVisible,
    setBannerSideBarVisible,
    selectBanner,
    setSelectBanner,
  } = useContext(MainContext);

  // Local State
  const [errorText, setErrorText] = useState("");
  const [errorImg, setErrorImg] = useState(false);
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");

  // Yup schema
  const createSchema = object({
    banner_name: string().required().trim(),
    banner_url: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(createSchema),
  });

  useEffect(() => {
    setPreview("");
    setImage("");
    setErrorText("");
    if (bannerSideBarVisible && type === "edit") {
      setImage(selectBanner.banner_image);
      setValue("banner_name", selectBanner.banner_name);
      setValue("banner_url", selectBanner.banner_url);
    } else {
      reset();
    }
  }, [
    bannerSideBarVisible,
    reset,
    selectBanner.banner_image,
    selectBanner.banner_name,
    selectBanner.banner_url,
    setValue,
    type,
  ]);

  const handleImg = (e) => {
    // Check if files were selected
    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected.");
      return;
    }
    if (errorImg) {
      setErrorImg(false);
    }

    let file = e.target.files[0];
    setImage(file);

    let reader = new FileReader();

    reader.onload = () => {
      setPreview(reader.result);
    };

    reader.onerror = (err) => {
      console.error("FileReader error:", err);
    };

    reader.readAsDataURL(file);
  };

  const banner = async (data) => {
    if (type === "create") {
      if (image === "" || preview === "") {
        setErrorImg(true);
      } else {
        const body = new FormData();
        body.append("banner_name", data.banner_name);
        body.append("banner_url", data.banner_url);
        body.append("banner_image", image);
        await axios
          .post(process.env.REACT_APP_SITE_BANNER_CREATE, body)
          .then((res) => {
            setBannerData([res.data, ...bannerData]);
            notify("Banner Create!");
            setBannerSideBarVisible(false);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  banner(data);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            } else {
              setErrorImg(err.response?.data.errors);
            }
          });
      }
    } else {
      const body = new FormData();
      body.append("banner_name", data.banner_name);
      body.append("banner_url", data.banner_url);
      body.append("banner_image", image ? image : selectBanner.banner_image);

      await axios
        .put(
          `${process.env.REACT_APP_SITE_BANNER_UPDATE}${selectBanner.id}`,
          body
        )
        .then((res) => {
          setBannerData((prevBannerData) =>
            prevBannerData.map((banner) =>
              banner.id === res.data.id ? res.data : banner
            )
          );
          notify("Banner Update!");
          setBannerSideBarVisible(false);
          setSelectBanner({
            banner_name: "",
            banner_url: "",
            banner_image: "",
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                banner(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorImg(err.response?.data.errors);
          }
        });
    }
  };

  return (
    <div
      className={
        bannerSideBarVisible
          ? " isOpenMenu file-manager-folder-sidebar-menu scroll"
          : "file-manager-folder-sidebar-menu scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Banner</h4>
        <div
          className="icon"
          onClick={() => {
            setBannerSideBarVisible(false);
            setSelectBanner({
              banner_name: "",
              banner_url: "",
              banner_image: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="folder-form edit-img"
          noValidate
          onSubmit={handleSubmit(banner)}
        >
          <div className="image-area">
            {/* <h2 className="main-caption">Main</h2> */}
            <div className="form-group">
              <label
                htmlFor="add-main-photo"
                className={errorImg ? "add error" : "add "}
              >
                <div className="icon">
                  <img src={addFile} alt="addfile" />
                </div>
                Choose file
              </label>
              <input
                type="file"
                name="add-main-photo"
                id="add-main-photo"
                onChange={handleImg}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="banner_name" className="inp-caption">
              Name
            </label>
            <input
              type="text"
              className={errors.banner_name ? "inp error" : "inp"}
              id="banner_name"
              name="banner_name"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("banner_name")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="banner_url" className="inp-caption">
              Url
            </label>
            <input
              type="text"
              className={errors.banner_url ? "inp error" : "inp"}
              id="banner_url"
              name="banner_url"
              placeholder="https://naftalan.biz/hotelId or another link"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("banner_url")}
            />
          </div>
          {(image || preview) && (
            <>
              <div className="side-bar-divader"></div>
              <div className="folder-img">
                <img
                  src={
                    preview ? preview : `${process.env.REACT_APP_IMAGE}${image}`
                  }
                  alt="img"
                />
              </div>
            </>
          )}

          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <div className="btn-area">
              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BannerSideBAr;
